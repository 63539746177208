<template>
  <main>
    <v-navigation-drawer dark v-model="drawer" absolute temporary>
      <template v-slot:prepend>
        <v-list-item>
          <v-list-item-content>
            <v-img
              :alt="flipdata.page_info.site_name"
              :src="flipdata.page_info.site_icon"
            />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title style="text-center">
              <h3 style="color: #fff">
                All Pages ({{ flipdata.items.length }})
              </h3></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-divider></v-divider>
      <v-list style="background-color: #2b2b2b !important">
        <v-list-item
          v-for="(item, i) in flipdata.items"
          :key="i"
          link
          @click="gotoPage(i + 1)"
        >
          <v-list-item-content>
            <v-list-item-title
              ><p style="width: 100%; text-align: center !important">
                Page {{ i + 1 }}
              </p></v-list-item-title
            >
            <div
              style="
                max-width: 100px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 15px;
              "
            >
              <v-img :src="item" max-width="100" class="mx-auto"> </v-img>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title style="text-center">
              <h3 style="color: #fff">Find Us:</h3></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title style="text-center">
              <center>
                <v-btn
                  color="primary"
                  icon
                  link
                  @click="externalUrl(flipdata.page_info.site_social_facebook)"
                >
                  <v-icon>mdi-facebook</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  icon
                  link
                  @click="externalUrl(flipdata.page_info.site_social_instagram)"
                >
                  <v-icon>mdi-instagram</v-icon>
                </v-btn>
              </center></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-navigation-drawer>
    <Flipbook
      ref="flipbook"
      class="flipbook"
      :singlePage="singlePage"
      :pages="pages"
      v-slot="flipbook"
      @flip-right-start="onFlipRightStart"
      :style="'width: 100%;'"
      centering
      :startPage="selectedPage"
    >
      <!-- <center>
        <img
          :alt="flipdata.page_info.site_name"
          :src="flipdata.page_info.site_icon"
          width="25%"
        />
      </center> -->
      <div>
        <v-toolbar
          dark
          dense
          absolute
          width="100%"
          style="position: fixed; top: 0px"
        >
          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
          <v-spacer></v-spacer>
          <!-- <img
            :alt="flipdata.page_info.site_name"
            :src="flipdata.page_info.site_icon"
            height="100%"
          /> -->
          <span>{{ flipdata.page_info.katalog_title }}</span>
          <v-spacer></v-spacer>
          <v-btn icon link @click="download">
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <v-btn icon link @click="externalUrl(flipdata.base_url)">
            <v-icon>mdi-share</v-icon>
          </v-btn>
          <v-btn icon link @click="playMusicToggle">
            <v-icon v-if="playMusic==true">mdi-music</v-icon>
            <v-icon v-if="playMusic==false">mdi-music-off</v-icon>
          </v-btn>
        </v-toolbar>
      </div>

      <v-container>
        <v-row>
          <v-col class="px-4">
            <v-card
              height="100%"
              rounded="0"
              outlined
              color="#2f2f2f"
              dark
              class="py-2 mx-2"
            >
              <!-- <button @click="changePage">1</button> -->
              <!-- <p>{{ flipbook }}</p> -->
              <!-- <p @click="flipbook.page = 1">1</p> -->
              <!-- {{ flipbook.numPages }} -->
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div>
        <v-toolbar
          dark
          dense
          bottom
          absolute
          width="100%"
          style="position: fixed; bottom: 0px"
        >
          <span v-if="isSmallScreen == false">{{
            flipdata.page_info.site_name
          }}</span>
          <v-spacer />
          <v-btn color="primary" @click="flipbook.flipLeft" icon>
            <v-icon>mdi-chevron-left-circle</v-icon>
          </v-btn>
          <v-btn color="primary" @click="flipbook.zoomIn" icon>
            <v-icon>mdi-magnify-plus-outline</v-icon>
          </v-btn>
          <span v-if="isSmallScreen == false">
            Page {{ flipbook.page }} of {{ flipbook.numPages }}
          </span>
          <v-btn color="primary" @click="flipbook.zoomOut" icon>
            <v-icon>mdi-magnify-minus-outline</v-icon>
          </v-btn>
          <v-btn color="primary" @click="flipbook.flipRight" icon>
            <v-icon>mdi-chevron-right-circle</v-icon></v-btn
          >
          <v-spacer />
          <v-btn
            color="primary"
            icon
            link
            @click="externalUrl(flipdata.page_info.site_social_facebook)"
            v-if="isSmallScreen == false"
          >
            <v-icon>mdi-facebook</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            icon
            link
            @click="externalUrl(flipdata.page_info.site_social_instagram)"
            v-if="isSmallScreen == false"
          >
            <v-icon>mdi-instagram</v-icon>
          </v-btn>
        </v-toolbar>
      </div>
    </Flipbook>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <div v-show="false">
            <audio controls autoplay loop id="b" ref="b">
            <source src="https://www.primageotex.co.id/assets/backsound.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
          </audio>
    </div>
  </main>
</template>


<script>
import Flipbook from "flipbook-vue";

export default {
  name: "Home",

  components: {
    Flipbook,
  },

  data: () => ({
    drawer: null,
    flipdata: {
      active: null,
      src: "",
      base_url: "",
      items: [],
      page_info: {},
    },
    selectedPage: 1,
    overlay: null,
    playMusic: true,
  }),

  computed: {
    pages() {
      return this.flipdata.items;
    },
    singlePage() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
    isSmallScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return false;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },

    screenHeight() {
      return screen.height;
    },
  },

  methods: {
    getImages() {
      this.overlay = true;
      this.$axios
        .get("https://www.primageotex.co.id/katalog/images")
        .then((response) => {
          this.flipdata = response.data;
          this.overlay = false;
          this.metaInfo = {
            title: this.flipdata.page_info.katalog_meta_title,
            description: this.flipdata.page_info.katalog_meta_description,
            keywords: this.flipdata.page_info.katalog_meta_keywords,
            favicon: this.flipdata.page_info.site_favicon,
            htmlAttrs: {
              lang: "en",
              amp: true,
            },
          };
        });
    },

    changePage() {
      this.$refs.flipbook.frontImage = this.$refs.flipbook.pageUrl(0);
      this.$refs.flipbook.backImge = this.$refs.flipbook.pageUrl(5);
      this.$refs.flipbook.firstPage = 4;
      this.$refs.flipbook.secondPage = 5;

      // this.$refs.flipbook.flipAuto(true);
    },
    onFlipRightStart(page) {
      console.log(page);
    },
    gotoPage(n) {
      this.selectedPage = n;
    },
    download() {
      window.location = this.flipdata.src;
    },
    externalUrl(url) {
      window.location = url;
    },
    playMusicToggle(){
      this.playMusic = !this.playMusic

      var b = document.getElementById("b");
      if(this.playMusic == false){
        b.pause();
      }else{
        b.play();
      }
    }
  },

  mounted() {
    this.getImages();

    var b = document.getElementById("b");
    b.volume = 0.4;
    b.play();
  },
};
</script>


<style>
html,
body {
  margin: 0;
  padding: 0;
  background-color: #2f2f2f;
}
a {
  color: inherit;
}
.action-bar {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.action-bar .btn {
  font-size: 30px;
  color: #999;
}
.action-bar .btn svg {
  bottom: 0;
}
.action-bar .btn:not(:first-child) {
  margin-left: 10px;
}
.has-mouse .action-bar .btn:hover {
  color: #ccc;
  filter: drop-shadow(1px 1px 5px #000);
  cursor: pointer;
}
.action-bar .btn:active {
  filter: none !important;
}
.action-bar .btn.disabled {
  color: #666;
  pointer-events: none;
}
.action-bar .page-num {
  font-size: 12px;
  margin-left: 10px;
}
.flipbook {
  padding-top: 30px;
  width: 90vw;
  height: 87vh;
}
.flipbook .viewport {
  width: 90vw;
  height: 100%;
}
.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
}
.credit {
  font-size: 12px;
  line-height: 20px;
  margin: 10px;
}

.st {
  display: block;
  position: sticky;
  bottom: 45px;
  right: 15px;
  width: 100px;
  border-radius: 15px;
  background-color: #ff6c19 !important;
  opacity: 0.3;
}
.st audio {
  width: 0;
  border-radius: 15px;
}
.st:hover {
  opacity: 1;
  transition: all 100ms;
  width: 260px;
  background-color: #ff6c19 !important;
}
.st:hover audio {
  width: 200px;
}
</style>


